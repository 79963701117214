interface InputFieldProps {
  name: string;
  type?: string;
  value: string;
  onChange: (e: React.ChangeEvent<any>) => void;
  onBlur: (e: React.FocusEvent<any>) => void;
  touched: boolean;
  error: string | undefined;
  className: string;
  errorClassName?: string;
  placeholder: string;
}

const InputField: React.FC<InputFieldProps> = ({
  className,
  name,
  type = "text",
  value,
  onChange,
  onBlur,
  touched,
  error,
  errorClassName,
  placeholder = "",
}) => {
  const isError = touched && error;
  return (
    <div>
      <input
        className={`${className} ${isError ? errorClassName : ""}`}
        type={type}
        id={name}
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        placeholder={placeholder}
      />
      {isError && (
        <div data-testid={name} style={{ color: "red" }}>
          {error}
        </div>
      )}
    </div>
  );
};

export default InputField;
