import InputField from "../../../InputField";
import { UseAutocomplete } from "../../../CityDropdown";

interface PersonalSectionDataProps {
  formik: {
    values: {
      name: string;
      lastName: string;
      city: string;
      telNumber: string;
    };
    errors: {
      name?: string;
      lastName?: string;
      city?: string;
      telNumber?: string;
    };
    touched: {
      name?: boolean;
      lastName?: boolean;
      city?: boolean;
      telNumber?: boolean;
    };
    handleChange: (e: React.ChangeEvent<any>) => void;
    handleBlur: (e: React.FocusEvent<any>) => void;
  };
}

export const PersonalSection: React.FC<PersonalSectionDataProps> = ({
  formik,
}) => {
  const cityOptions = ["", "София", "Бургас"];
  return (
    <>
      <form className="credential-form">
        <InputField
          name="name"
          value={formik.values.name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          touched={formik.touched.name || false}
          error={formik.errors.name}
          className="credential-input"
          placeholder="Име"
          errorClassName="error-input"
        />

        <InputField
          name="lastName"
          value={formik.values.lastName}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          touched={formik.touched.lastName || false}
          error={formik.errors.lastName}
          className="credential-input"
          placeholder="Фамилия"
          errorClassName="error-input"
        />
        <UseAutocomplete
          name="city"
          value={formik.values.city}
          onChange={(newValue: string | null) =>
            formik.handleChange({
              target: {
                name: "city",
                value: newValue as any,
              },
            } as React.ChangeEvent<any>)
          }
          onBlur={formik.handleBlur}
          touched={formik.touched.city || false}
          error={formik.errors.city}
          className="credential-input"
          placeholder="Населено място"
          options={cityOptions}
        />

        <InputField
          name="telNumber"
          value={formik.values.telNumber}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          touched={formik.touched.telNumber || false}
          error={formik.errors.telNumber}
          className="credential-input"
          placeholder="Телефонен номер"
          errorClassName="error-input"
        />
      </form>
    </>
  );
};
