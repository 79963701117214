import * as React from "react";
import {
  useAutocomplete,
} from "@mui/base/useAutocomplete";
import { styled } from "@mui/system";

interface DropdownFieldProps {
  name: string;
  type?: string;
  value: string;
  onChange: (newValue: string | null) => void;
  onBlur: (e: React.FocusEvent<any>) => void;
  touched: boolean;
  error: string | undefined;
  className: string;
  placeholder: string;
  options: string[];
}

export const UseAutocomplete: React.FC<DropdownFieldProps> = ({
  value,
  onChange,
  onBlur,
  touched,
  error,
  placeholder,
  options,
}) => {
  const {
    getRootProps,
    getInputLabelProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    focused,
  } = useAutocomplete({
    id: "use-autocomplete",
    options: options,
    getOptionLabel: (option) => option,
    value: value,
    onChange: (event, newValue) => onChange(newValue),
  });

  return (
    <div>
      <StyledAutocompleteRoot
        {...getRootProps()}
        className={focused ? "focused" : ""}
      >
        <StyledInput {...getInputProps()} placeholder={placeholder} />
      </StyledAutocompleteRoot>
      {groupedOptions.length > 0 && (
        <StyledListbox {...getListboxProps()}>
          {(groupedOptions as string[]).map((option, index) => (
            <StyledOption {...getOptionProps({ option, index })}>
              {option}
            </StyledOption>
          ))}
        </StyledListbox>
      )}

      {touched && error && (
        <div style={{ color: "red" }}>
          {error}
        </div>
      )}
    </div>
  );
};

const StyledAutocompleteRoot = styled("div")(
  ({ theme }) => `
  font-size: 2rem;
  border-radius: 1000px;
  color: ${
    theme.palette.mode === "dark"
      ? theme.palette.neutral.dark
      : theme.palette.neutral.light
  };
  background-color: ${
    theme.palette.mode === "dark" ? theme.palette.neutral.dark : "white"
  };
  display: flex;
  gap: 5px;
  padding: 2rem;
  overflow: hidden;
  width: 100%;

  &.focused {
    border-color: ${theme.palette.primary.main};
  &:hover {
    border-color: "red";
  }
`
);

const StyledInput = styled("input")(
  ({ theme }) => `
  font-size: 2rem;
  font-family: inherit;
  color: "dark";
  background: inherit;
  border: none;
  outline: 0;
  flex: 1 0 auto;
`
);

const StyledListbox = styled("ul")(
  ({ theme }) => `
  font-size: 1.5rem;
  box-sizing: border-box;
  padding: 6px;
  margin: 12px 0;
  width:30%;
  border-radius: 12px;
  overflow: auto;
  outline: 0px;
  max-height: 300px;
  z-index: 1;
  position: absolute;
  background: ${
    theme.palette.mode === "dark" ? theme.palette.neutral.dark : "white"
  };
  border: 1px solid ${
    theme.palette.mode === "dark"
      ? theme.palette.neutral.dark
      : theme.palette.neutral.light
  };
  color: "dark";
  box-shadow: 0px 2px 3px ${
    theme.palette.mode === "dark" ? "rgba(0,0,0, 0.50)" : "rgba(0,0,0, 0.05)"
  };
  `
);

const StyledOption = styled("li")(
  ({ theme }) => `
  list-style: none;
  padding: 8px;
  border-radius: 8px;
  cursor: default;

  &:last-of-type {
    border-bottom: none;
  }

  &:hover {
    cursor: pointer;
  }
  `
);
