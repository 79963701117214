import { useState, useEffect, useRef } from "react";
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import Button from "@mui/material/Button";
import "./dogProfileCard.css";
import FileUpdate from './FileUpdate';

const DogProfileCard = ({ pet }: any) => {
    const inputRef = useRef<any>(null);
    const [image, setImage] = useState('' || pet.image)

    const handleImageClick = () => {
        if(inputRef.current !== null) {
            inputRef.current.click();
        }
    }

    const handleImageChange = (event: any) => {
        const file = event.target.files[0];
        console.log(file);

        setImage(file);
    }

    const image_update_styles = {
        width: "11vw",
        height: "10vw",
        marginBottom: "-1vw",
        marginLeft: "-0.4vw"
    }

    return (
        <div className="card_containter">
            <div className="card-content">
                {/* <img src="./tongue.png" alt="dog" className="dog_image" />  */}
            
            {pet.image != "" ? (

                 <div onClick={ handleImageClick} className="image-wrapper">
                        <img src={ URL.createObjectURL(image) }  style={{ width: "11vw", height: "10vw", borderRadius: "50%"}} ></img>
                        <div className="uploader">
                            <input type="file" ref={inputRef}  onChange={handleImageChange} className="edit-image" />
                            
                            <div style={{position:"absolute", bottom: "1vw", left: "4.5vw", cursor: "pointer", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginBottom: "-1vw", marginLeft: "-0.4vw"}}>
                                <CameraAltOutlinedIcon sx={{fontSize: "2vw", color: "#fff"}}></CameraAltOutlinedIcon>
                                <p>Update</p>
                            </div>
                            
                        </div>
                    </div>
            ): (
                    <div style={{ opacity: "0.5", width: "30%", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center"}}>
                        <AccountBoxOutlinedIcon sx={{fontSize: "8rem"}}></AccountBoxOutlinedIcon> 
                    </div>
            )}

                <div className="dog-details-container">
                    <div className="dog-details-header">
                        <p className="dog-name">{pet.name}</p>
                        <p className="dog-breed">{pet.breed}</p>
                    </div>

                    <div className="main-container">
                        <div className="left-container">
                            <p className="dog-address">{pet.city + ', ' + pet.region}</p>
                            <p className="dog-sex">{pet.sex}</p>
                            <p className="dog-color">{pet.color}</p>
                            
                            <div className="checbox-container">
                                {pet.vaccinated === true ? (
                                <TaskAltOutlinedIcon sx={{ color: "#65C466", fontSize: "1.4vw", mr: "0.6vw"}}></TaskAltOutlinedIcon>     
                                ): (
                                    <CancelOutlinedIcon  sx={{ color: "#d41107", fontSize: "1.4vw", mr: "0.6vw"}}></CancelOutlinedIcon>
                                )}
                                <p style={{width: "100%"}}>Напълно ваксиниран(а)</p>
                            </div>

                            <div className="checbox-container">
                                {pet.original_breed === true ? (
                                <TaskAltOutlinedIcon sx={{ color: "#65C466", fontSize: "1.4vw", mr: "0.6vw"}}></TaskAltOutlinedIcon>     
                                ): (
                                    <CancelOutlinedIcon  sx={{ color: "#d41107", fontSize: "1.4vw", mr: "0.6vw"}}></CancelOutlinedIcon>
                                )}
                                <p style={{width: "100%"}}>Потвърдено родословие</p>
                            </div>
                        </div>

                        <div className="right-container">
                            <div style={{ textAlign: "left"}}>
                                <p>{pet.weight + " кг"}</p>
                                <p>{pet.height + " см"}</p>
                                <p>6 м</p>     
                            </div>
                            

                            <div className="buttons">
                                <Button
                                    sx={{ width: "18%", padding: "0.5rem", fontSize: "1.3rem", backgroundColor: "#65C466", "&:hover": {backgroundColor: "#5AB05B", transform: "scale(1.03)"} }}
                                    variant="contained"
                                    color="success" >
                                            Повече
                                </Button>

                                <div className="edit-btn">
                                    <ModeEditOutlinedIcon sx={{ fontSize: "1.8vw", color: "#fff"}}></ModeEditOutlinedIcon>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>   
        </div>
    );
};

export default DogProfileCard;