import "./sassStyles/main.css";
import "./fonts.css";
import "./app.css";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { createTheme, Theme } from "@mui/material/styles";
import { themeSettings } from "./themes/themeSettings";

import { BrowserRouter } from "react-router-dom";
import { Route, Routes } from "react-router-dom";

import { Layout } from "./helpers/Layout";
import { RequireAuth } from "./helpers/RequireAuth";

//import { Register } from "./components/common/register/Register";
import { Register } from "./components/common/register/Register";

import { Home } from "./components/common/home/Home";
import { LandingV2 } from "./components/landing_new/LandingV2";
import { GDPR } from "./components/landing_new/GDPR/GDPR";
import { Stories } from "./components/landing/stories/Stories";


import { Navbar } from "./components/navbar/Navbar";
import { CreateDogForm } from "./components/dogform/CreateDogForm";
import { Login } from "./components/common/login/Login";
import { UserProfile } from "./components/common/profile/user/UserProfile";


import { useMemo } from "react";
import { useTypedSelectorTheme } from "./features/themeSlice";

import { AuthProvider } from "react-oidc-context";

const App = () => {
  const mode = useTypedSelectorTheme((state) => state.theme.mode) as
    | "light"
    | "dark";
  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);

  const oidcConfig = {
    authority: "http://keycloak-staging.dogapp.com/realms/master/",
    client_id: "ReactClient-123",
    redirect_uri: "http://localhost:3000/home",
    // ...
  };
  return (
    <>
      <AuthProvider {...oidcConfig}>
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Routes>
              <Route path="/" element={<Layout />}>
                <Route path="/home" element={<Home />} />

                <Route path="/dogform" element={<CreateDogForm />} />

                <Route path="/navbar" element={<Navbar />} />
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />

                <Route path="/profile" element={<UserProfile />} />
                <Route path="/gdpr" element={<GDPR />} />
                <Route path="/team" element={<Stories />} />

                <Route path="/" element={<LandingV2/>} />
                
              </Route>
            </Routes>
          </ThemeProvider>
        </BrowserRouter>
      </AuthProvider>
    </>
  );
};

export default App;
