import React from 'react';

import {
  IconButton,
  InputBase,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";

import { useEffect } from "react";

import AccountMenu from "./AccountMenu";
import { NotificationsMenu } from "./notificationsMenu/NotificationsMenu";
import { ChatMenu } from "./chatMenu/ChatMenu";

import { Search } from "@mui/icons-material";
import { useDispatch } from "react-redux";

import { useNavigate } from "react-router-dom";
import FlexBetween from "../FlexBetween";

import { setMessages } from "../../features/messagesSlice";
import { setNotifications } from "../../features/notificationsSlice";
import { listData,notifications } from "./TestData";

export const Navbar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");

  const theme = useTheme();
  const neutralLight = theme.palette.neutral.light;

  const primaryLight = theme.palette.primary.light;
  const alt = theme.palette.background.alt;


  const testKey = process.env.REACT_APP_TEST_KEY;

  useEffect(() => {
    dispatch(setMessages(listData));
    dispatch(setNotifications(notifications));
    console.log(testKey);
  }, []);

  return (
    <>
      <FlexBetween
        padding="1rem 6%"
        sx={{
          backgroundColor: alt,
        }}
      >
        <FlexBetween gap="1.75rem">
          <Typography
            fontWeight="bold"
            variant="h3"
            color="primary"
            onClick={() => navigate("/home")}
            sx={{
              "&:hover": {
                color: primaryLight,
                cursor: "pointer",
              },
            }}
          >
            Dog App
          </Typography>
          {isNonMobileScreens && (
            <FlexBetween
              sx={{
                backgroundColor: neutralLight,
              }}
              borderRadius="9px"
              gap="3rem"
              padding="0.1rem 1.5rem"
            >
              <InputBase placeholder="Търсене..." />
              <IconButton>
                <Search />
              </IconButton>
            </FlexBetween>
          )}
        </FlexBetween>

        {/* DESKTOP NAV */}

        <FlexBetween gap="2rem">
          <NotificationsMenu />
          <ChatMenu />
          <AccountMenu />
        </FlexBetween>
      </FlexBetween>
    </>
  );
};
