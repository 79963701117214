import React from "react";
import "./footer.css";

import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from '@mui/icons-material/LinkedIn';

import { useTranslation } from "react-i18next";

import websiteLogo from "../../../assets/paw-white.png";

export const Footer = () => {
  const { t } = useTranslation();
  return (
    <footer className="primary-footer">
      <div className="footer-container">
        <div className="primary-footer-wrapper">

          <div className="primary-footer-logo-social">
            <div className="footer-logo-cont"> 
              <a href="#">
                <img
                  src={websiteLogo}
                  alt="Logo"
                  className="footer-logo"
                />
              </a>
              <div >
                <p style={{color: "#fff"}}>PetConnect 2024.</p>
                <p style={{color: "#fff"}}>Всички права са запазени</p>
              </div>
              
            </div>
             
            <div className="socials">
              <p>Последвайте ни</p>
              <ul className="social-list" role="list" aria-label="Social links">
                <li>
                  <span aria-label="facebook">
                    <FacebookIcon className="social-icon" />
                  </span>
                </li>
    
                <li>
                  <span aria-label="instragram">
                    <InstagramIcon className="social-icon" />
                  </span>
                </li>

                <li>
                  <span aria-label="linkedin">
                    <LinkedInIcon className="social-icon" />
                  </span>
                </li>
              </ul>  
            </div>
          </div>
      
            <nav className="footer-nav">
              <ul className=""  aria-label="Footer" role="list">
                <li>
                  <a href="/team">Нашият екип</a>
                </li>
                <li>
                  <a href="/gdpr">Уведомление за поверителност</a>
                </li>
                <li>
                  <a href="#">Свържете се с нас</a>
                </li>
              </ul>
            </nav>
          
          
        </div>
      </div>
    </footer>
  );
};
