import "./profileSummary.css";
import React, { useState } from "react";

import { ThemeProvider, createTheme  } from '@mui/material/styles';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';

import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';

import FileUpload from "./FileUpload";
import FileUpdate from './FileUpdate';


export default function ProfileSummary () {
    const [profileImage, setProfileImage] = useState("");

    const profile_upload_styles = {
        fontSize: "4vw", 
        color: "#7a7a83", 
        cursor: "pointer",
        "&:hover": { color: "#65C466" }
    }

    const profile_update_styles = {
        width: "8vw",
        height: "8vw",
        marginBottom: "-0.7vw",
        marginLeft: "-1.6vw"
    }

    return (
        <div className="profile-summary-cont">

            {profileImage && typeof profileImage !== 'string' ? (
                <FileUpdate styles={ profile_update_styles } image={ profileImage } setImage={ setProfileImage }></FileUpdate>
                ): (
                <div className="upload-cont">
                    <FileUpload styles={ profile_upload_styles } image={ profileImage } setImage={ setProfileImage }></FileUpload>
                </div>
            )}

            <div className="user-details">
                <div className="top-details">
                    <p className="user-name">Илиян Неделчев</p>
                    <div className="actions">
                        <div className="action-cont">
                            <PersonAddAltOutlinedIcon sx={{ fontSize: "1.8vw", color: "#fff"}}></PersonAddAltOutlinedIcon>
                        </div>
                        <div className="action-cont">
                            <SmsOutlinedIcon sx={{ fontSize: "1.8vw", color: "#fff"}}></SmsOutlinedIcon>
                        </div>

                        <div className="action-cont">
                            <ModeEditOutlinedIcon sx={{ fontSize: "1.8vw", color: "#fff"}}></ModeEditOutlinedIcon>
                        </div>
                    </div>
                </div>
                <p className="user-address">София, София-град</p>
            </div>

            <ClearOutlinedIcon sx={{ fontSize: "2.3vw", ml: "5vw", mt: "1vw", cursor: "pointer"}}></ClearOutlinedIcon>


        </div>
    );
};
