import React from 'react';
import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { CreateDogForm } from "../../dogform/CreateDogForm"
import "./modal.css";

const backdrop = {
    visible: { opacity: 1},
    hidden: { opacity: 0},
}

const modal = {
    hidden: {
        y: "-100vh",
        opacity: 0
    },
    visible: {
        y: "10vh",
        opacity: 1,
        transition: {
            delay: 0.5
        }
    }
}

const  Modal = ({ showModal, setShowModal, pets, setPets }: any) => {
    return (
        <AnimatePresence mode='wait'>
            { showModal && (
               <motion.div className="backdrop" variants={ backdrop } initial="hidden" animate="visible" exit="hidden">

                    <motion.div className="modal" variants={ modal }>
                        <CreateDogForm showModal= { showModal } setShowModal={ setShowModal } pets={ pets } setPets={ setPets }></CreateDogForm>
                    </motion.div>

               </motion.div> 
            )}
        </AnimatePresence>
    );
}

export default Modal;
 

