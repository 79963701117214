import React, { useState } from "react";
import { useTheme } from "@mui/material";
import Menu from "@mui/material/Menu";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import InputBase from "@mui/material/InputBase";
import FlexBetween from "../../FlexBetween";
import { Message, Search } from "@mui/icons-material";
import Box from "@mui/material/Box";
import { IconWithBadge } from "../badge/IconWithBadge";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { NotificationList } from "./NotificationList";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import { useTypedSelectorNotification } from "../../../features/notificationsSlice";

export const NotificationsMenu = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const theme = useTheme();
  const missedNotifications = useTypedSelectorNotification(
    (state) => state.notifications.unreadNotifications
  );

  const handleChipClick = () => {};
  return (
    <>
      <Tooltip title="Известия">
        <IconButton
          onClick={handleClick}
          sx={{
            borderRadius: "50%",
            position: "relative",
          }}
          size="small"
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          <IconWithBadge
            icon={<NotificationsIcon />}
            badgeCount={missedNotifications}
            iconStyles={{
              fontSize: "25px",
              color: theme.palette.iconColor,
            }}
          />
        </IconButton>
      </Tooltip>

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "auto",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 56,
              height: 56,
              ml: -2,
              mr: 1,
            },
          },
        }}
      >
        <Box
          sx={{
            width: "300px",
            p: "10px",
            display: "flex",
            flexDirection: "column",
            gap: "15px",
          }}
        >
          <Typography>Известия</Typography>
          <Stack direction="row" spacing={1}>
            <Chip
              label="Всички"
              variant="outlined"
              onClick={handleChipClick}
              sx={{
                "&:hover": {
                  borderColor: theme.palette.primary.light,
                },
                "&:focused": {
                  borderColor: theme.palette.primary.light,
                },
              }}
            />
            <Chip
              label="Непрочетени"
              variant="outlined"
              onClick={handleChipClick}
              sx={{
                "&:hover": {
                  borderColor: theme.palette.primary.light,
                },
              }}
            />
          </Stack>
          <NotificationList />
        </Box>
      </Menu>
    </>
  );
};
