import { useState, useEffect } from "react";
import "./login.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import InputField from "../register/InputField";
import Header from "../headers/Header";

import DogAnimated from "../dogs/DogAnimated";
import TransitionalAlert from "./TransitionalAlert";
import { Box, Button, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";

import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import GoogleIcon from "@mui/icons-material/Google";
import { RegisterSnackBar } from "../register/Snackbar";
import { usePageRefresh } from "../../../hooks/usePageRefresh";

export const Login = () => {

  const HOST_NAME = "http://localhost:8080";
  const realm = "myRealm";
  const client_id = "myclient";
  const client_secret = "d4nc3bgz3hC4jXOjBK6I9vqrSBEkmkUo";

  const URL = HOST_NAME + "/realms/" + realm + "/protocol/openid-connect/token";

  let access_token = "";

  const [snackbarOpen, setSnackBarOpen] = useState(() => {
    const source =
      localStorage.getItem("source") === "register" &&
      JSON.parse(localStorage.getItem("showSnackbar") ?? "false");
    return source;
  });

  const [loginFailed, setLoginFailed] = useState(false);
  const [formSubmited, setIsFormSubmitted] = useState(false);
  const { palette } = useTheme();

  const handleCloseSnackbar = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackBarOpen(false);
  };

  const initialValues = {
    email: "",
    password: "",
  };

  const validationSchema = Yup.object({
    email: Yup.string().email("Невалиден имейл")
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      // Handle form submission logic here
      console.log(values);

      let body = {
        "grant_type": "password",
        "client_id": client_id,
        "client_secret": client_secret,
        "username": values.email,
        "password": values.password
      };

      let params = {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(body)
      };

      const fetchData = async () => {

        const result = await fetch(URL, params);

        return result.json().then(json => {
          console.log(json);
        })
      }

      fetchData();

      console.log("Form submitted with values:", values);
    },
    initialTouched: {
      email: false,
      password: false,
    },
  });

  const handleFormSubmit = () => {
    formik.handleSubmit();
    setIsFormSubmitted(true);
    setLoginFailed(false);
    setTimeout(() => {
      debugger;
      if (!Boolean(formik.errors.email)) {
      } else {
        if (formik.values.password === "123") {

        } else {
          setLoginFailed(true);
        }
      }
      setIsFormSubmitted(false);
    }, 3000);
  };

  usePageRefresh();

  return (
    <div>
      <section className="login-section">
        <div className="login-section__form">
          <Header></Header>

          <div className="dog_container">
          </div>
          <DogAnimated></DogAnimated>

          
          <div className="login-section__form__container">
            <InputField
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              touched={formik.touched.email || false}
              error={formik.errors.email}
              className="credential-input"
              placeholder="Имейл"
              errorClassName="error-input"
            />
            <InputField
              name="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              touched={formik.touched.password || false}
              error={formik.errors.password}
              className="credential-input"
              placeholder="Парола"
              type="password"
              errorClassName="error-input"
            />

            {loginFailed && (
              <TransitionalAlert></TransitionalAlert>
            )}

            <Typography
              sx={{
                textDecoration: "underline",
                textAlign: "right",
                color: palette?.neutral?.dark,
                fontSize: "1.7rem",
                marginLeft: "clamp(10vw, 25vw, 25vw)",
                marginTop: "-1rem",
                width: "fit-content",
                "&:hover": {
                  cursor: "pointer",
                  color: palette.primary.light,
                },
              }}
            >
              Забравена парола?
            </Typography>

            <Box>
              <Button
                type="submit"
                onClick={handleFormSubmit}
                disabled={formSubmited}
                sx={{
                  width: "64%",
                  m: "2rem 1rem 0 0",
                  p: "0.5rem",
                  borderRadius: "3rem",
                  fontSize: "1.8rem",
                  backgroundColor: palette.primary.main,
                  color: palette?.neutral?.dark,

                  "&:hover": {
                    backgroundColor: palette.primary.light,
                  },
                }}
              >
                Влизане
              </Button>
              <Link to="/register">
                <Typography
                  onClick={() => { }}
                  sx={{
                    textDecoration: "underline",
                    color: palette?.neutral?.dark,
                    m: "1rem 0 0 0 ",
                    "&:hover": {
                      cursor: "pointer",
                      color: palette.primary.light,
                    },
                  }}
                >
                  Нямате акаунт? Регистрирайте се тук.
                </Typography>
              </Link>

              <Typography
                sx={{
                  color: palette?.neutral?.dark,
                  fontSize: "2rem",
                  textAlign: "left",
                  marginLeft: "8.2rem",
                  marginBottom: "0.8rem",
                  marginTop: "2rem",
                }}
              >
                Или влезте със
              </Typography>

              {/**<div className={styles.separator}></div>*/}

              <div className="separator_lines">
                <div className="line"></div>
                <div className="line"></div>
              </div>

              <Box
                display="flex"
                justifyContent="start"
                gap="4rem"
                marginTop="1rem"
                marginLeft="4rem"
                flexWrap="wrap"
                sx={{
                  alignItems: "center",
                }}
              >
                <FacebookIcon
                  sx={{
                    color: "#1877F2",
                    fontSize: "4rem",
                    cursor: "pointer",
                    "&:active": {
                      transform: "scale(0.9)"
                    }
                  }}
                />
                <TwitterIcon
                  sx={{
                    color: "#1DA1F2",
                    fontSize: "4rem",
                    cursor: "pointer",
                    "&:active": {
                      transform: "scale(0.9)"
                    }
                  }}
                />
                <GoogleIcon
                  sx={{
                    fontSize: "4rem",
                    cursor: "pointer",
                    color: "#DB4437",
                    "&:active": {
                      transform: "scale(0.9)"
                    }
                  }}
                />
              </Box>
            </Box>
          </div>
        </div>

        <div className="side_panel">
        {/*   <RegisterSnackBar
            snackbarOpen={snackbarOpen}
            handleCloseSnackBar={handleCloseSnackbar}
          /> */}

        {/* <div className="dog_container">
          <DogAnimated></DogAnimated>
        </div> */}
        

        </div>

      </section>

      <section className="right_side">
      </section>
    </div>

  );
};
