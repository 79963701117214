import React from "react";
import "./home.css";
import { useAuth } from "react-oidc-context";


export const Home: React.FC = () => {
  const auth= useAuth();
  return (
    <div>
      <h1>Hello</h1>
    </div>
  );
};
