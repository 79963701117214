import { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import PersonAdd from "@mui/icons-material/PersonAdd";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import { DarkMode, LightMode } from "@mui/icons-material";

import { useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setMode } from "../../features/themeSlice";
import { StyledMenuItem } from "./StyledMenuItem";

export default function AccountMenu() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const mode = useSelector((state: any) => state.theme.mode);
  const dispatch = useDispatch();
  const theme = useTheme();
  const dark = theme.palette.neutral.dark;
  const iconColor = theme.palette.iconColor;

  console.log("test")

  return (
    <>
      <Tooltip title="Account settings">
        <IconButton
          onClick={handleClick}
          size="small"
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          <Avatar
            sx={{ width: 32, height: 32 }}
            src="https://t3.ftcdn.net/jpg/02/43/12/34/360_F_243123463_zTooub557xEWABDLk0jJklDyLSGl2jrr.jpg"
          />
        </IconButton>
      </Tooltip>

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "auto",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            }
          },
        }}

      >
        <StyledMenuItem>
          <Avatar src="https://t3.ftcdn.net/jpg/02/43/12/34/360_F_243123463_zTooub557xEWABDLk0jJklDyLSGl2jrr.jpg" />{" "}
          <Typography>Профил</Typography>
        </StyledMenuItem>
        <Divider />

        <MenuItem onClick={() => dispatch(setMode())}>
          <ListItemIcon>
            {theme.palette.mode === "dark" ? (
              <DarkMode sx={{ color: iconColor}} fontSize="large"/>
            ) : (
              <LightMode sx={{ color: iconColor }} fontSize="large"/>
            )}
          </ListItemIcon>
          <Typography>Смени темата</Typography>
        </MenuItem>
        <MenuItem>
          <ListItemIcon>
            <PersonAdd sx={{ color: iconColor }} fontSize="large" />
          </ListItemIcon>
          <Typography>Добави акаунт</Typography>
        </MenuItem>
        <MenuItem>
          <ListItemIcon>
            <Settings sx={{ color: iconColor }} fontSize="large" />
          </ListItemIcon>
          <Typography>Настройки</Typography>
        </MenuItem>
        <MenuItem>
          <ListItemIcon>
            <Logout sx={{ color: iconColor }} fontSize="large" />
          </ListItemIcon>
          <Typography>Изход</Typography>
        </MenuItem>
      </Menu>
    </>
  );
}
