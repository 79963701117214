import "./register.css";
import { Button } from "@mui/material";

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

//import { TypeSection } from "./registerSections/typeSelectionSection/TypeSection";
import { CredentialsSection } from "./registerSections/credentialsSection/CredentialsSection";

//import { BusinessSection } from "./registerSections/typeFormSection/businessSection/BusinessSection";
import { PersonalSection } from "./registerSections/typeFormSection/personalSection/PersonalSection";
//import { VeterinarianSection } from "./registerSections/typeFormSection/veterinarianSection/VeterinarianSection";

// import { RegisterStepper } from "./stepper/RegisterStepper";

import SendIcon from "@mui/icons-material/Send";

import { useFormik } from "formik";
import * as Yup from "yup";

import websiteLogo from "../../../images/paw-glitch.png";
import Header from "../headers/Header";

import {SimpleStepper} from "./stepper/SimpleStepper";
import DogAnimated from "../dogs/DogAnimated";

const steps = ["Данни", "Данни2"];

interface CredentialsData {
  email: string;
  password: string;
  repassword: string;
  name: string;
  lastName: string;
  city: string;
  telNumber: string;
}

export const Register = (): JSX.Element => {
  const [activeStep, setActiveStep] = useState(0);
  const [selectedType, setSelectedType] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const initialValues = {
    email: "",
    password: "",
    repassword: "",
    name: "",
    lastName: "",
    city: "",
    telNumber: "",
  };

  const validationSchema = Yup.object({
    email: Yup.string().email("Невалиден имейл").required("Задължително поле"),
    password: Yup.string()
      .min(6, "Паролата трябва да бъде поне 6 символа")
      .required("Задължително поле"),
    repassword: Yup.string()
      .oneOf([Yup.ref("password")], "Паролите не съвпадат")
      .required("Задължително поле"),
    name: Yup.string().required("Задължително поле"),
    lastName: Yup.string().required("Задължително поле"),
    city: Yup.string().required("Задължително поле"),
    telNumber: Yup.number().required("Задължително поле"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      // Handle form submission logic here
      console.log("Form submitted with values:", values);
    },
    initialTouched: {
      email: false,
      password: false,
      repassword: false,
    },
  });

  const step1FieldErrors =
    formik.values.email === "" ||
    formik.values.password === "" ||
    formik.values.repassword === "";

  const step1Errors: boolean =
    activeStep === 0 &&
    (step1FieldErrors ||
      Boolean(formik.errors.email) ||
      Boolean(formik.errors.password) ||
      Boolean(formik.errors.repassword));

  const disableSubmitButton: boolean = step1Errors;

  const handleTypeSelected = (type: string) => {
    setSelectedType(type);
    handleNext();
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const register = async (values: CredentialsData) => {
    const registerResponse = await fetch(
      "http://localhost:3001/auth/register",
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(values),
      }
    );
    const registered = await registerResponse.json();
    if (registered) {
      // Logic for handling the registration
      //navigate("/login");
    }
  };

  const handleFormSubmit = async (values: CredentialsData) => {
    await register(values);
    console.log(values);
  };

  const submitData = () => {
    formik.handleSubmit();
    setIsLoading(true);
    setTimeout(() => {
      localStorage.setItem("showSnackbar", JSON.stringify(true));
      localStorage.setItem("source", "register");
      navigate("/login");
    }, 1500);
  };
  return (
    <section className="register-section">
      <div className="register-section__form">
        
        <Header></Header>

        <div className="register-section__form__container">

          <SimpleStepper steps={steps} activeStep={activeStep}></SimpleStepper>
          {/* <div className="dog_container">
          </div> */}

          <DogAnimated></DogAnimated>

          {/**<div className="register-section__form__container__stepper">
            <RegisterStepper steps={steps} activeStep={activeStep} />
  </div>*/}

          {/**
           There will be no type selection for now, will be implemented at a later stage. 
          {activeStep === 0 && (
            <TypeSection onTypeSelected={handleTypeSelected} />
          )}
          */}

          {activeStep === 0 && <CredentialsSection formik={formik} />}

          {activeStep === 1 && (
            <>
              <PersonalSection formik={formik} />

              {/**
               The Veterinary and Business sections will be implemented on a later stage.
               The logic for the handling of the selected type will be commented out as well.

              {selectedType === "Veterinary" && <VeterinarianSection />}

              {selectedType === "Business" && <BusinessSection />}
              */}
            </>
          )}

          <div className="register-section__form__action-buttons">
            <Button
              className="action-button"
              variant="contained"
              color="primary"
              disabled={activeStep === 0}
              onClick={handleBack}
            >
              Назад
            </Button>

            <Button
              className="action-button"
              variant="contained"
              color="primary"
              disabled={disableSubmitButton || isLoading}
              endIcon={activeStep !== steps.length - 1 ? <SendIcon /> : ""}
              onClick={
                activeStep === steps.length - 1 ? submitData : handleNext
              }
            >
              {activeStep === steps.length - 1 ? "Регистриране" : "Напред"}
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
};
