import React, { useState } from "react";
import Menu from "@mui/material/Menu";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import InputBase from "@mui/material/InputBase";
import FlexBetween from "../../FlexBetween";
import { Message, Search } from "@mui/icons-material";
import Box from "@mui/material/Box";
import { IconWithBadge } from "../badge/IconWithBadge";
import { ChatList } from "./ChatList";
import { useTypedSelectorMessage } from "../../../features/messagesSlice";
import { useTheme } from "@mui/material/styles";

export const ChatMenu: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const newMessages = useTypedSelectorMessage(
    (state) => state.messages.unreadMessages
  );

  const theme = useTheme();

  return (
    <>
      <Tooltip title="Чатове">
        <IconButton
          onClick={handleClick}
          sx={{
            borderRadius: "50%",
            position: "relative",
          }}
          size="small"
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          <IconWithBadge
            icon={<Message />}
            badgeCount={newMessages}
            iconStyles={{
              fontSize: "25px",
              color: theme.palette.iconColor,
            }}
          />
        </IconButton>
      </Tooltip>

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "auto",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 56,
              height: 56,
              ml: -2,
              mr: 1,
            },
          },
        }}
      >
        <Box
          sx={{
            width: "300px",
            p: "10px",
            display: "flex",
            flexDirection: "column",
            gap: "15px",
          }}
        >
          <Typography>Чатове</Typography>
          <FlexBetween
            borderRadius="9px"
            gap="3rem"
            padding="0.1rem 1.5rem"
            sx={{
              backgroundColor:
                theme.palette.mode == "light" ? "#F0F2F5" : "#3A3B3C",
            }}
          >
            {/* TODO : Add the colors provided above to the palette*/}
            <InputBase placeholder="Търсене" />
            <IconButton>
              <Search />
            </IconButton>
          </FlexBetween>
          <ChatList />
        </Box>
      </Menu>
    </>
  );
};
