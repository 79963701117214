import logo from "../../../../images/paw-glitch.png";
import CustomizedSwitch from "./CustomizedSwitch";
import EmptyAvatar from "./EmptyAvatar";
import AccountMenu from "../../../navbar/AccountMenu";
import { NotificationsMenu } from "../../../navbar/notificationsMenu/NotificationsMenu";
import { ChatMenu } from "../../../navbar/chatMenu/ChatMenu";
import "./profileHeader.css";

import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';


export default function ProfileHeader () {

  return (
    <div className="login-section__form__header">
        <div className="left-cont">
            <div className="logo-cont">
                <img src={logo} className="logo-img"/>
            </div>
            <p>PawsConnect</p>
        </div>
        
        <div className="right-cont">
            <CustomizedSwitch></CustomizedSwitch>
            
            <div className="icons">
               {/* <NotificationsNoneOutlinedIcon sx={{ fontSize: 42, cursor: "pointer"}}></NotificationsNoneOutlinedIcon>
               <MessageOutlinedIcon sx={{ fontSize: 42, cursor: "pointer"}}></MessageOutlinedIcon>
               <EmptyAvatar></EmptyAvatar> */}

              <NotificationsMenu />
              <ChatMenu />
              <AccountMenu />
            </div>
        </div>
    </div>
  );
};
