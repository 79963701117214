import React from "react";
import "./header.css";
import { useTranslation } from "react-i18next";

import { NavLink as Link } from "react-router-dom";
import { LandingNavbar } from "../navbar/LandingNavbar";

const scrollToSection = (sectionId: string): void => {
    const targetSection = document.getElementById(sectionId);

    if (targetSection) {
      window.scrollTo({
        top: targetSection.offsetTop,
        behavior: "smooth",
      });
    }
};

export const Header: React.FC = () => {

    return (
        <header className="header cont-row">

            <div style={{ width: "100%", marginTop: "1vw", marginLeft: "3vw" }} className="cont-row">

                <div className="glitch_wrap">
                    <div className="header__logo glitch"></div>
                </div>
                <div className="glitch-text_wrap" style={{ marginLeft: "-2vw"}}>
                    <p data-text="PetConnect" className="glitch-text fast-anim">PetConnect</p>
                </div>

            </div>

           {/*  <div className="header__buttons-box">
                <LandingNavbar></LandingNavbar>
            </div> */}

            <div className="header__text-box">
                <h1 className="heading-primary">
                    
                   {/*  <div className="glitch-text_wrap">
                        <p className="header_main_text">С <span className="text-underline-effect" data-text="любов">любов</span> и <span className="text-underline-effect" data-text="грижа">грижа</span></p>
                    </div>
        
                    <p className="header_sub_text">за вашия домашен любимец</p> */}

                    <div className="header-main-text-cont">
                        <p className="header_main_text">Една платформа за всичко</p>
                    </div>
        
                    <p className="header_sub_text">от което се нуждае Вашия любимец</p>
                </h1>

                <div className="learn-more-cont">
                    <a href="#test" className="learn-more-btn">Научете повече</a>
                </div>

            </div>
        </header>
    )
}