import "./dogProfile.css";
import { useState } from 'react';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import Modal from "../../modal/Modal";
import DogProfileCard from "./DogProfileCard";

export default function DogProfile () {
  const [showModal, setShowModal] = useState(false);
  
  const [pets, setPets] = useState([
    {
      name: "Арес",
      breed: "Алабай",
      region: "Пловдив",
      city: "Пловдив",
      sex: "Мъжко",
      color: "Черен",
      weight: "87",
      height: "73",
      birthday: "",
      vaccinated: true,
      original_breed: true,
      image: "",
      document_image: ""
    }
  ]); 

  const handleModal = function() {
      setShowModal(true);
  }

  /* TODO:
      1. Calculate the age based on the birthday date
      2. Add edit functionality in the DogProfileCard component
      3. Add edit functionality in the ProfileSummary component
      4. Theme selection functionality
      5. Extend the ProfileHeader component using as a template the Navbar component
      6. Add tooltips to all image buttons
   */

  return (

    <div className="outter-cont">
      
      {pets.map((pet)=>{
        return <DogProfileCard pet={ pet }></DogProfileCard>
      })}
      
      <div className="add-profile-cont">
        <Modal showModal={ showModal } setShowModal={ setShowModal } pets={ pets } setPets={ setPets }></Modal>
        
        <div className="inner-cont">
            <p>Добави кучешки/котешки профил </p>
            <AddCircleOutlineOutlinedIcon sx={{ 
                                        fontSize: "6vw", 
                                        color: "#7a7a83", 
                                        cursor: "pointer",
                                        "&:hover": { color: "#65C466" }}} onClick={handleModal}>
            </AddCircleOutlineOutlinedIcon>
        </div>
      </div>

    </div>
    
  );
};
