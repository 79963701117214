import * as React from 'react';
import logo from "../../../images/paw-glitch.png";

export default function Header() {
  return (
    <div className="login-section__form__header">
        <div className="logo-cont">
            <img src={logo} className="logo-img"/>
        </div>
        <p>PawsConnect</p>
    </div>
  );
}