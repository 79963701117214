import { useState } from "react";
import "./dogAnimated.css";

import dog_ears from "../../../images/dog_ears_outline4.png";
import dog_nose from "../../../images/nose3.png";
import dog_tongue from "../../../images/tongue.png";

const DogAnimated = () => {

    return (
        <div className="dog_cont">
            <div className="dog_face">
                <img src={dog_ears} className="dog_ears"/>

                <div className="dog_eyes">
                    <div className="eye eyeLeft">
                        <div className="glow"></div>
                    </div>
                    <div className="eye eyeRight">
                        <div className="glow"></div>
                    </div>
                </div>

                <div className="dog_nose">
                    <img src={dog_nose} />
                </div>

                <div className="dog_mouth">
                    <svg className="dog_smile" viewBox="-2 -2 84 23" width="96%" height="100%">
                        <path d="M0 0c3.76 9.279 9.69 18.98 26.712 19.238 17.022.258 10.72.258 28 0S75.959 9.182 79.987.161" fill="none" stroke-width="3" stroke-linecap="square" stroke-miterlimit="3"></path>
                    </svg>
                    <div className="mouthHole"></div>
                    <div className="tongue_new breath">
                        <img src={dog_tongue}/>
                    </div>
                    
                    
                </div>
            </div>
        </div>
    );
};


export default DogAnimated;