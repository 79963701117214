import React, { useState } from "react";
import "./accordion.css";

import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';

import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from '@mui/icons-material/LinkedIn';

export default function CustomAccordion({ config, accordions, setAccordions }: any) {

    const petIcon = document.getElementById(config.id);

    const handleExpand = () => {

        const myNextList = [...accordions];

        myNextList.forEach(accordion => {
            accordion.expanded = false;
            const petIcon = document.getElementById(accordion.id);
            if(petIcon?.classList.contains('show-pet')) {
                petIcon?.classList.remove('show-pet');
            }
        });

        const newAccordion = myNextList.find(
          a => a.id == config.id
        );

        newAccordion.expanded = true;

        const newPetIcon = document.getElementById(newAccordion.id);
        newPetIcon?.classList.add('show-pet');
        setAccordions(myNextList);
    }

    const handleCollapse = () => {
        petIcon?.classList.remove('show-pet');
        const myNextList = [...accordions];
        
        const newAccordion = myNextList.find(
            a => a.id == config.id
        );

        newAccordion.expanded = false;
        setAccordions(myNextList);
    }

    return (
        
            <div className="accordion_cont">
                <img src={config.image} id={config.id} className="pet-icon" />

                <Accordion expanded={config.expanded} className="accordion" onClick={() => {
                        
                        if(petIcon?.classList.contains('show-pet')) {
                            handleCollapse();
                        }
                        else {
                            handleExpand();
                        }
                    }}>
                    <AccordionSummary
                        expandIcon={
                            config.expanded == true ? (<RemoveOutlinedIcon className="expand-icon"/>): (<AddOutlinedIcon className="expand-icon"/>)
                        }
                        aria-controls="panel1-content"
                        id="panel1-header"
                        className="accordion-summary"
                    >
                        {config.q}
                    </AccordionSummary>

                    <AccordionDetails className="faq-text">
                        {config.a}
                        {(config.socials === true) ? (
                            <ul className="social-list" role="list" aria-label="Social links">
                            <li>
                              <span aria-label="facebook">
                                <FacebookIcon className="social-icon" />
                              </span>
                            </li>
                
                            <li>
                              <span aria-label="instragram">
                                <InstagramIcon className="social-icon" />
                              </span>
                            </li>
                          </ul>
                        ): ("")}
                    </AccordionDetails>
                </Accordion>
            </div>

    );
}