import React, { useEffect } from "react";
import "./features.css";
import { FaDog, FaPhotoVideo, FaSearchLocation  } from "react-icons/fa"
import { BiCommentDetail } from "react-icons/bi";



export const Feature: React.FC = () => {
  let dogs = require('../../../assets/dogs.png');


useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("show");
        } else {
          entry.target.classList.remove("show");
        }
      });
    });

    const hiddenElements = document.querySelectorAll(".hidden");
    hiddenElements.forEach((el) => observer.observe(el));
  },[]);

  return (
    <section className="section-features">
      <img src={dogs} style={{}} className="features_heading_img"/>
      <div className="features-heading">
        <span className="">Защо да изберете нас</span>
      </div>
      <div className="row">
        <div className="col-1-of-4">
          <div className="feature-box hidden">
            <svg width="0" height="0">
              <linearGradient
                id="blue-gradient"
                x1="100%"
                y1="100%"
                x2="0%"
                y2="0%"
              >
                <stop stopColor="#55c57a" offset="0%" />
                <stop stopColor="#28b485" offset="100%" />
              </linearGradient>
            </svg>
            <div>
              <FaDog
                className="feature-box__icon icon-basic"
                style={{ fill: "url(#blue-gradient)" }}
              />
              <FaDog
                className="feature-box__icon icon-basic"
                style={{ fill: "url(#blue-gradient)", transform: "rotateY(180deg)" }}
              />
            </div>

            <h3 className="feature-main-text">Намерете лесно партньор на вашия любимец</h3>

            <p className="feature-sub-text">
              С помощта на нашия <br/><span className="gradient_green">Partner Finder</span>
            </p>
          </div>
        </div>

        <div className="col-1-of-4">
          <div className="feature-box hidden">
            <svg width="0" height="0">
              <linearGradient
                id="blue-gradient"
                x1="100%"
                y1="100%"
                x2="0%"
                y2="0%"
              >
                <stop stopColor="#55c57a" offset="0%" />
                <stop stopColor="#28b485" offset="100%" />
              </linearGradient>
            </svg>
            <div>
              <BiCommentDetail
                className="feature-box__icon icon-basic"
                style={{ fill: "url(#blue-gradient)" }}
              />
            </div>

            <h3 className="feature-main-text">Намерете отговори на въпроси за вашия любимец</h3>

            <p className="feature-sub-text">
              В темите на нашия <br/><span className="gradient_green">Форум</span>
            </p>
          </div>
        </div>

        <div className="col-1-of-4">
          <div className="feature-box hidden">
            <svg width="0" height="0">
              <linearGradient
                id="blue-gradient"
                x1="100%"
                y1="100%"
                x2="0%"
                y2="0%"
              >
                <stop stopColor="#55c57a" offset="0%" />
                <stop stopColor="#28b485" offset="100%" />
              </linearGradient>
            </svg>
            <div>
              <FaPhotoVideo
                className="feature-box__icon icon-basic"
                style={{ fill: "url(#blue-gradient)" }}
              />
            </div>

            <h3 className="feature-main-text">Открийте и споделяйте забавно съдържание</h3>

            <p className="feature-sub-text">
             В нашата <br/><span className="gradient_green">Социална мрежа</span>
            </p>
          </div>
        </div>

        <div className="col-1-of-4">
          <div className="feature-box hidden">
            <svg width="0" height="0">
              <linearGradient
                id="blue-gradient"
                x1="100%"
                y1="100%"
                x2="0%"
                y2="0%"
              >
                <stop stopColor="#55c57a" offset="0%" />
                <stop stopColor="#28b485" offset="100%" />
              </linearGradient>
            </svg>
            <div>
              <FaSearchLocation 
                className="feature-box__icon icon-basic"
                style={{ fill: "url(#blue-gradient)" }}
              />
            </div>

            <h3 className="feature-main-text">Открийте по-лесно и бързо вашия изгубен любимец</h3>

            <p className="feature-sub-text">
              Използвайки нашия <br/><span className="gradient_green">Pet Finder</span>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};
