import React, { useEffect, useState } from "react";
import "./subscribe.css";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from "@mui/material/Button";

import { createTheme, ThemeProvider } from '@mui/material/styles'

import MenuIcon from '@mui/icons-material/Menu';
import MarkEmailReadRoundedIcon from '@mui/icons-material/MarkEmailReadRounded';
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import LocalFireDepartmentOutlinedIcon from '@mui/icons-material/LocalFireDepartmentOutlined';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import PermMediaOutlinedIcon from '@mui/icons-material/PermMediaOutlined';
import { FaRegHandshake } from "react-icons/fa";

export const Subscribe: React.FC = () => {

    let [subscribed, setSubscribed] = useState(false);
    const [checkboxes, setCheckboxes] = React.useState({
        gdpr: false,
        marketing: false
    });

    const [subscribeForm, setSubscribeForm] = React.useState({
        name: "",
        email: ""
    });

    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState(false);

    let Logo = require('../../../assets/paw-glitch-bright.png');
    let dog_and_human = require('../../../assets/dog_and_human.png');

    const subscribe_form = { 
        name: "",
        email: ""
    };

    const { gdpr, marketing } = checkboxes;

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        console.log(gdpr);
        setCheckboxes({
            ...checkboxes,
            [event.target.name]: event.target.checked,
        });
    };

    const handleSubscribe = () => {
        setSubscribed(true);

     /*    const subscribeCont = document.getElementById('subscribed');
        subscribeCont?.classList.add('show-subscribe'); */
    }

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);
        if (e.target.validity.valid) {
          setEmailError(false);
        } else {
          setEmailError(true);
        }
    };

    useEffect(() => {
        const intersectionObserver = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              entry.target.classList.add("show");
            } else {
              entry.target.classList.remove("show");
            }
          });
        });
    
        const hiddenElementsLeft = document.querySelectorAll(".hide-left");
        const hiddenElementsRight = document.querySelectorAll(".hide-right");

        hiddenElementsLeft.forEach((el) => intersectionObserver.observe(el));
        hiddenElementsRight.forEach((el) => intersectionObserver.observe(el));

    },[]);

    const newTheme = (theme: any) => createTheme({
        ...theme,
        components: {
            MuiFormHelperText: {
                styleOverrides: {
                    root: {
                        fontSize: '1.2rem',
                        color: "red",
                        fontFamily: "Ubuntu"
                    }
                }
            }
        }
    })

    return (
        <div className="section-subscribe">
            {/* <div className="u-center-text u-margin-bottom-big">
                <h2 className="heading_secondary">
                    Всичко, от което има нужда вашия любимец
                </h2>
                <p className="heading-sub-text">достъпно лесно и удобно от едно място!</p>

            </div> */}

            <div className="main-container">
                { subscribed == true ? (
                    <div id="subscribed" className="subscribed-cont">
                        <CheckCircleIcon sx={{ fontSize: "9vw", color: "rgba(40, 180, 133, 0.7)"}} />
                        <p className="heading-sub-text">Успешно записване в списъка с чакащи!</p>

                        <p className="heading-sub-text">Ще получите имейл с потвърждение. </p>
                    </div>) : (
                    <div className="form-container hide-left">
                        <div className="text-container">
                            <h2 className="mobile_header_txt">Бъдете сред първите изпробвали нашата платформа</h2>
                            <p className="mobile-sub-txt">Просто попълнете нашата кратка форма</p>

                            <div className="form-header-text">
                                <MarkEmailReadRoundedIcon className="form-header-img"/>
                                <p>Ние ще ви известим с имейл когато платформата е активна за ползване.</p>
                            </div>
                        </div>

                        <div className="form-inputs-container">
                            <div className="form-inputs">
                                <FormControl className="form-input-field">
                                    <ThemeProvider theme={newTheme}>
                                        <TextField
                                            required
                                            id="username"
                                            label="Име"
                                            name="name"
                                            value={subscribeForm.name}
                                            variant="outlined"
                                            color="success"
                                            sx={{ width: "100%", fontSize: "1.4rem", marginBottom: "1.2rem" }}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                setSubscribeForm((data) => ({
                                                ...data,
                                                [event.target.name]: event.target.value,
                                                }));
                                            }}

                                            helperText={" "}

                                            InputProps={{
                                                endAdornment: (
                                                <InputAdornment position="end">
                                                    {subscribeForm.name.length >= 3 && (
                                                    <CheckCircleIcon color="success" sx={{ color: "#65C466", fontSize: "2rem" }}/>
                                                    )}
                                                </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </ThemeProvider>
                                </FormControl>

                                <FormControl className="form-input-field">
                                    <ThemeProvider theme={newTheme}>
                                        <TextField
                                            required
                                            id="email"
                                            label="Имейл"
                                            name="email"
                                            value={email}
                                            variant="outlined"
                                            color="success"
                                            sx={{ width: "100%", fontSize: "1.4rem", marginBottom: "1.2rem" }}
                                            /* onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                                setSubscribeForm((data) => ({
                                                ...data,
                                                [event.target.name]: event.target.value,
                                                }));
                                            }} */

                                            onChange={handleEmailChange}
                                            helperText={emailError ? "Моля въведете валиден имейл адрес" : " "}
                                            
                                            InputProps={{
                                                endAdornment: (
                                                <InputAdornment position="end">
                                                    {(email.length > 0 && !emailError) && (
                                                    <CheckCircleIcon color="success" sx={{ color: "#65C466", fontSize: "2rem" }}/>
                                                    )}
                                                </InputAdornment>
                                                ),
                                                type: "email"
                                            }}
                                        />
                                    </ThemeProvider>
                                </FormControl>
                            </div>
                            
                            <div className="form-checks">
                                <div className="checkbox-container">
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                onChange={handleChange}
                                                name="gdpr"
                                                checked={gdpr}
                                                className="checkbox"
                                            />
                                        }
                                        label=""
                                    /> 
                                    <p className="checkbox-label">
                                        Съгласен/а съм да получавам информационни съобщения относно развитието на платформата PetConnect и с <a className="gdpr-link" href="/gdpr">Уведомлението за поверителност</a> <span className="mandatory">*</span>
                                    </p>    
                                </div>
                                
                                <div className="checkbox-container">
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                onChange={handleChange}
                                                name="marketing"
                                                checked={marketing}
                                                className="checkbox"
                                            />
                                        }
                                        label=""
                                    /> 
                                    <p className="checkbox-label">
                                        Съгласен/а съм личните ми данни да бъдат обработвани за целите на последващ директен маркетинг, свързан с изпращането на търговски и маркетингови съобщения
                                    </p>    
                                </div>
                            </div>
                        </div>

                        <Button
                            className="form-button"
                            variant="contained"
                            color="success"
                            type="submit"
                            onClick={handleSubscribe}
                            disabled={gdpr !== true || subscribeForm.name.length < 3 || email == ''}
                        >
                            Искам да бъда информиран
                        </Button>

                        <div className="message-cont">
                            <p className="message-text">Ние няма да споделим вашата информация с никого!</p>
                            <FaRegHandshake className="message-icon"/>
                        </div>
                
                    </div>
                )}

                <div className="phone-container">
                    <div className="phone hide-right">
                        <div className="phone-nav">
                            <div className="glitch_mobile_wrap">
                                <div className="header_logo glitch_mobile"></div>
                            </div>

                            <MenuIcon sx={{ fontSize: "3vw", transform: "rotateZ(-9deg)", mt: "-1vw"}}></MenuIcon>
                        </div>
                        
                        {/* <p className="phone-txt">PawsConnect</p> */}

                        <div className="features">
                            <div className="feature" style={{ marginLeft: "1vw" }}>
                                <FavoriteBorderOutlinedIcon sx={{ fontSize: "1.3vw", color: "rgba(40, 180, 133, 0.7)" }}></FavoriteBorderOutlinedIcon>
                                <p className="feature-txt">Намери партньор</p>
                            </div>

                            <div className="feature" style={{ marginLeft: "1.5vw" }}>
                                <PermMediaOutlinedIcon sx={{ fontSize: "1.3vw", color: "rgba(40, 180, 133, 0.7)" }}></PermMediaOutlinedIcon>
                                <p className="feature-txt">Социална мрежа</p>
                            </div>

                            <div className="feature" style={{ marginLeft: "2vw" }}>
                                <MessageOutlinedIcon sx={{ fontSize: "1.3vw", color: "rgba(40, 180, 133, 0.7)" }}></MessageOutlinedIcon>
                                <p className="feature-txt">Форум</p>
                            </div>
                        </div>

                        <img src={dog_and_human} style={{ width: "13vw", height: "13vw", marginTop: "2vw", marginLeft: "4vw" }}></img>
                    </div>
                </div>
            </div>
        </div>

        
    )
}