import "./credentialSection.css";
import InputField from "../../InputField";

interface CredentialsSectionDataProps {
  formik: {
    values: {
      email: string;
      password: string;
      repassword: string;
    };
    errors: {
      email?: string;
      password?: string;
      repassword?: string;
    };
    touched: {
      email?: boolean;
      password?: boolean;
      repassword?: boolean;
    };
    handleChange: (e: React.ChangeEvent<any>) => void;
    handleBlur: (e: React.FocusEvent<any>) => void;
  };
}
export const CredentialsSection: React.FC<CredentialsSectionDataProps> = ({
  formik,
}) => {
  return (
    <>
      <form className="credential-form">
        <InputField
          name="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          touched={formik.touched.email || false}
          error={formik.errors.email}
          className="credential-input"
          placeholder="Имейл"
          errorClassName="error-input"
        />
        <InputField
          name="password"
          value={formik.values.password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          touched={formik.touched.password || false}
          error={formik.errors.password}
          className="credential-input"
          placeholder="Парола"
          type="password"
          errorClassName="error-input"
        />

        <InputField
          name="repassword"
          value={formik.values.repassword}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          touched={formik.touched.repassword || false}
          error={formik.errors.repassword}
          className="credential-input"
          placeholder="Повтори парола"
          type="password"
          errorClassName="error-input"
        />
      </form>
    </>
  );
};
